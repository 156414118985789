import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,2];

export const dictionary = {
		"/": [4],
		"/admin": [~5,[2]],
		"/admin/order": [~6,[2]],
		"/auth": [~7],
		"/betingelser": [8],
		"/cart": [~9],
		"/favorites": [10],
		"/item/next_drop": [12],
		"/item/[item_id]": [11],
		"/order/[order_id]": [~13],
		"/payment/begin": [16],
		"/payment/subscription": [~17],
		"/payment/subscription/succes": [~18],
		"/payment/[order_id]": [~14],
		"/payment/[order_id]/[reference]": [~15],
		"/profile": [19,[3]],
		"/profile/addresses": [20,[3]],
		"/profile/info": [21,[3]],
		"/profile/orders": [22,[3]],
		"/profile/payments": [23,[3]],
		"/profile/subscription": [24,[3]],
		"/search": [25]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';